var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalEmployee === true ? _c('div', [_c('transition', {
    attrs: {
      "name": "model"
    }
  }, [_c('div', {
    staticClass: "modal-mask-ChangeFile"
  }, [_c('div', {
    staticClass: "modal-wrapper-ChangeFile"
  }, [_c('div', {
    staticClass: "modal-dialog-ChangeFile"
  }, [_c('div', {
    staticClass: "modal-content-ChangeFile"
  }, [_c('div', {
    staticClass: "modalChangeFile"
  }, [_c('div', {
    staticClass: "text-center mb-6 justify-center"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-5"
  }, [_vm._v("Import Employee")])]), _c('div', {
    staticClass: "border-solid"
  }, [!this.fileList.length ? _c('div', {
    staticClass: "border-dashed border-2 w-3/5 px-8 py-5 text-center m-auto mb-4 rounded-lg",
    on: {
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.dragFile.apply(null, arguments);
      },
      "dragover": [function ($event) {
        $event.preventDefault();
      }, _vm.dragover]
    }
  }, [_c('p', {
    staticClass: "my-4 text-sm"
  }, [_vm._v(" Drag & Drop File "), _c('br'), _vm._v(" Or ")]), _c('input', {
    ref: "file",
    staticClass: "w-px h-px opacity-0 overflow-hidden absolute",
    attrs: {
      "type": "file",
      "id": "file"
    },
    on: {
      "change": _vm.onChange
    }
  }), _c('label', {
    staticClass: "cursor-pointer border px-10 py-2 font-semibold bg-yellow text-white text-center rounded-lg",
    attrs: {
      "for": "file"
    }
  }, [_vm._v("Select File")])]) : _vm._e(), this.fileList.length ? _c('div', {
    staticClass: "border-dashed border-2 w-3/5 px-8 py-5 m-auto mb-4 relative"
  }, [_c('p', {
    staticClass: "cursor-pointer bg-red-400 w-6 h-6 text-base font-semibold text-center text-white rounded-full absolute right-5",
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("x")]), _vm._l(_vm.fileList, function (data) {
    return _c('div', {
      key: data.id,
      staticClass: "w-full h-auto py-8"
    }, [_c('p', {
      staticClass: "h-auto text-center truncate ... w-full"
    }, [_vm._v(_vm._s(data.name))])]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "flex justify-center gap-10"
  }, [_c('button', {
    staticClass: "w-28 h-9 font-semibold bg-yellow text-white text-center rounded-lg",
    on: {
      "click": _vm.saveChangeFile
    }
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "w-28 h-9 font-semibold border-yellow-400 border-2 text-yellow text-center rounded-lg",
    on: {
      "click": _vm.cancelChangeFile
    }
  }, [_vm._v("Cancel")])])])])])])])])], 1) : _vm._e(), _vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Employee List")]), _c('div', {
    staticClass: "space-x-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add",
      "type": "primary"
    },
    on: {
      "action": _vm.addData
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Import",
      "type": "secondary"
    },
    on: {
      "action": _vm.importEmployee
    }
  })], 1)]), _c('div', {
    staticClass: "bg-white p-4 overflow-hidden shadow-small rounded-lg card"
  }, [_vm._l(_vm.filters, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "p-0 mb-12"
    }, [item.field != 'btn' ? _c('div', {
      staticClass: "bg-white p-4 shadow-small rounded-lg card"
    }, [_c('div', {
      staticClass: "grid grid-cols-6 justify-start gap-2 mb-4"
    }, [_c('div', {}, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Department")]), _c('Dropdown', {
      attrs: {
        "options": _vm.dropdownItems.department,
        "optionLabel": "name",
        "placeholder": "All",
        "isReset": _vm.isReset
      },
      model: {
        value: _vm.filterSelected.department,
        callback: function callback($$v) {
          _vm.$set(_vm.filterSelected, "department", $$v);
        },
        expression: "filterSelected.department"
      }
    })], 1), _c('div', {}, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Contract")]), _c('Dropdown', {
      attrs: {
        "options": _vm.dropdownItems.contract,
        "optionLabel": "name",
        "placeholder": "All",
        "isReset": _vm.isReset
      },
      model: {
        value: _vm.filterSelected.contract,
        callback: function callback($$v) {
          _vm.$set(_vm.filterSelected, "contract", $$v);
        },
        expression: "filterSelected.contract"
      }
    })], 1), _c('div', {}, [_c('label', {
      staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
    }, [_vm._v("Status")]), _c('Dropdown', {
      attrs: {
        "options": _vm.dropdownItems.status,
        "optionLabel": "name",
        "placeholder": "All",
        "isReset": _vm.isReset
      },
      model: {
        value: _vm.filterSelected.activeEmployee,
        callback: function callback($$v) {
          _vm.$set(_vm.filterSelected, "activeEmployee", $$v);
        },
        expression: "filterSelected.activeEmployee"
      }
    })], 1), _c('div', {
      staticClass: "grid grid-cols-2 h-full mt-5 ml-2"
    }, [_c('Button', {
      attrs: {
        "buttonText": "Filter",
        "type": "primary",
        "additionalClass": "mr-1 bg-yellow h-11"
      },
      on: {
        "action": _vm.onSearch
      }
    }), _c('Button', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isClearBtnActive,
        expression: "isClearBtnActive"
      }],
      attrs: {
        "buttonText": "Clear",
        "type": "tertiary",
        "additionalClass": " h-11"
      },
      on: {
        "action": _vm.clearFilter
      }
    })], 1), _c('div', {}, [_c('TextField', {
      staticClass: "w-full",
      attrs: {
        "type": "text",
        "label": item.label,
        "enterKeyAction": _vm.onSearch,
        "borderEnabled": "",
        "placeholder": item.label
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    })], 1), _c('div', {
      staticClass: "grid grid-cols-1 h-full mt-5 ml-1"
    }, [_c('Button', {
      attrs: {
        "buttonText": "Search",
        "type": "secondary",
        "additionalClass": "mr-1 bg-yellow h-11"
      },
      on: {
        "action": _vm.onSearch
      }
    })], 1)])]) : _vm._e()]);
  }), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: true
      },
      "row-style-class": _vm.rowStyleClassFn,
      "styleClass": "vgt-table bordered my-table"
    },
    on: {
      "on-sort-change": _vm.onSortChange,
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', {
          staticClass: "flex justify-center gap-2"
        }, [_c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "primary",
            "size": "icon",
            "forIcon": {
              height: '24',
              width: '24',
              color: 'white'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Eye');
            },
            "tooltip": {
              show: true,
              position: '100%',
              text: 'Detail'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.detailMaster(props.row.id);
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "forIcon": {
              color: 'yellow'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            },
            "tooltip": {
              show: true,
              position: '170%',
              text: 'Edit'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.editMaster(props.row.id);
            }
          }
        }), _c('Button', {
          attrs: {
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            },
            "tooltip": {
              show: true,
              position: '140%',
              text: 'Delete'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.showDeleteConfirmModal(props.row);
            }
          }
        })], 1) : props.column.field === 'status' ? _c('div', {
          staticClass: "center"
        }, [_c('span', {
          staticClass: "bg-green-10 text-green-100 px-2 py-2 text-sm rounded-lg font-semibold"
        }, [_vm._v(" " + _vm._s(props.row.activeEmployee ? 'Active' : 'Inactive') + " ")])]) : props.column.field === 'role' ? _c('div', [_c('span', {
          class: "".concat(props.row.role ? '' : 'text-neutral-250')
        }, [_vm._v(" " + _vm._s(props.row.role ? props.row.role : 'Unavailable') + " ")])]) : props.column.field === 'contract.name' ? _c('div', [_c('span', {
          class: "".concat(props.row.contract.name ? '' : 'text-neutral-250')
        }, [_vm._v(" " + _vm._s(props.row.contract.name ? props.row.contract.name : 'Unavailable') + " ")])]) : props.column.field === 'lastContractDate' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field])) + " ")])]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 2)]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isDeleteConfirmationModalVisible,
      "width": "640px",
      "id": "confirm"
    },
    on: {
      "close": _vm.toogleDeleteConfirmModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "py-5"
  }, [_c('div', {
    staticClass: "font-bold text-2xl"
  }, [_vm._v("Are you sure to delete employee?")]), _c('div', {
    staticClass: "py-5 text-sm"
  }, [_vm._v("This action can not be undone")]), _c('div', {
    staticClass: "flex justify-center items-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "type": "secondary",
      "size": "long",
      "additionalClass": "mr-16"
    },
    on: {
      "action": _vm.deleteMaster
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "primary",
      "size": "long"
    },
    on: {
      "action": _vm.toogleDeleteConfirmModal
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }