<template>
  <div>
    <div v-if="modalEmployee === true">
      <transition name="model">
        <div class="modal-mask-ChangeFile">
          <div class="modal-wrapper-ChangeFile">
            <div class="modal-dialog-ChangeFile">
              <div class="modal-content-ChangeFile">
                <!-- Isi -->
                <div class="modalChangeFile">
                  <div class="text-center mb-6 justify-center">
                    <p class="text-2xl font-bold mb-5">Import Employee</p>
                  </div>
                  <div class="border-solid">
                    <div @drop.prevent="dragFile" @dragover.prevent v-if="!this.fileList.length" @dragover="dragover" class="border-dashed border-2 w-3/5 px-8 py-5 text-center m-auto mb-4 rounded-lg">
                      <p class="my-4 text-sm">
                        Drag & Drop File <br />
                        Or
                      </p>
                      <input type="file" @change="onChange" ref="file" id="file" class="w-px h-px opacity-0 overflow-hidden absolute" />
                      <label for="file" class="cursor-pointer border px-10 py-2 font-semibold bg-yellow text-white text-center rounded-lg">Select File</label>
                    </div>
                    <div v-if="this.fileList.length" class="border-dashed border-2 w-3/5 px-8 py-5 m-auto mb-4 relative">
                      <p @click="remove" class="cursor-pointer bg-red-400 w-6 h-6 text-base font-semibold text-center text-white rounded-full absolute right-5">x</p>
                      <div v-for="data in fileList" :key="data.id" class="w-full h-auto py-8">
                        <p class="h-auto text-center truncate ... w-full">{{ data.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center gap-10">
                    <button class="w-28 h-9 font-semibold bg-yellow text-white text-center rounded-lg" @click="saveChangeFile">Save</button>
                    <button class="w-28 h-9 font-semibold border-yellow-400 border-2 text-yellow text-center rounded-lg" @click="cancelChangeFile">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else class="flex flex-col gap-6">
      <div class="flex justify-between items-center">
        <h1 class="text-3xl font-bold">Employee List</h1>
        <div class="space-x-4">
          <Button buttonText="Add" type="primary" @action="addData" />
          <Button buttonText="Import" type="secondary" @action="importEmployee" />
        </div>
      </div>

      <div class="bg-white p-4 overflow-hidden shadow-small rounded-lg card">
        <div class="p-0 mb-12" v-for="item in filters" v-bind:key="item.id">
          <div v-if="item.field != 'btn'" class="bg-white p-4 shadow-small rounded-lg card">
            <div class="grid grid-cols-6 justify-start gap-2 mb-4">
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Department</label>
                <Dropdown :options="dropdownItems.department" optionLabel="name" v-model="filterSelected.department" placeholder="All" :isReset="isReset" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Contract</label>
                <Dropdown :options="dropdownItems.contract" optionLabel="name" v-model="filterSelected.contract" placeholder="All" :isReset="isReset" />
              </div>
              <div class="">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Status</label>
                <Dropdown :options="dropdownItems.status" optionLabel="name" v-model="filterSelected.activeEmployee" placeholder="All" :isReset="isReset" />
              </div>
              <div class="grid grid-cols-2 h-full mt-5 ml-2">
                <Button buttonText="Filter" type="primary" @action="onSearch" additionalClass="mr-1 bg-yellow h-11" />
                <Button v-show="isClearBtnActive" buttonText="Clear" type="tertiary" @action="clearFilter" additionalClass=" h-11" />
              </div>

              <div class="">
                <TextField type="text" class="w-full" :label="item.label" :enterKeyAction="onSearch" borderEnabled v-model="options[item.field]" :placeholder="item.label" />
              </div>
              <div class="grid grid-cols-1 h-full mt-5 ml-1">
                <Button buttonText="Search" type="secondary" @action="onSearch" additionalClass="mr-1 bg-yellow h-11" />
              </div>
            </div>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalRecords"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: true
          }"
          @on-sort-change="onSortChange"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :row-style-class="rowStyleClassFn"
          styleClass="vgt-table bordered my-table"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex justify-center gap-2">
              <Button
                class="mr-2"
                type="primary"
                size="icon"
                :forIcon="{ height: '24', width: '24', color: 'white' }"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Eye')"
                :tooltip="{ show: true, position: '100%', text: 'Detail' }"
                @action="detailMaster(props.row.id)"
              />
              <Button
                class="mr-2"
                type="tertiary"
                size="icon"
                :forIcon="{ color: 'yellow' }"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                :tooltip="{ show: true, position: '170%', text: 'Edit' }"
                @action="editMaster(props.row.id)"
              />
              <Button
                type="delete"
                size="icon"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                :tooltip="{ show: true, position: '140%', text: 'Delete' }"
                @action="showDeleteConfirmModal(props.row)"
              />
            </div>
            <div v-else-if="props.column.field === 'status'" class="center">
              <span class="bg-green-10 text-green-100 px-2 py-2 text-sm rounded-lg font-semibold">
                {{ props.row.activeEmployee ? 'Active' : 'Inactive' }}
              </span>
            </div>
            <div v-else-if="props.column.field === 'role'">
              <span :class="`${props.row.role ? '' : 'text-neutral-250'}`">
                {{ props.row.role ? props.row.role : 'Unavailable' }}
              </span>
            </div>
            <div v-else-if="props.column.field === 'contract.name'">
              <span :class="`${props.row.contract.name ? '' : 'text-neutral-250'}`">
                {{ props.row.contract.name ? props.row.contract.name : 'Unavailable' }}
              </span>
            </div>
            <div v-else-if="props.column.field === 'lastContractDate'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]) }}
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!-- Changefe Modal -->

    <!-- End ChangeFile Modal -->
    <Modal :modalVisible="isDeleteConfirmationModalVisible" @close="toogleDeleteConfirmModal" width="640px" id="confirm">
      <template slot="modal-content">
        <div class="py-5">
          <div class="font-bold text-2xl">Are you sure to delete employee?</div>
          <div class="py-5 text-sm">This action can not be undone</div>
          <div class="flex justify-center items-center">
            <Button buttonText="Delete" type="secondary" size="long" additionalClass="mr-16" @action="deleteMaster" />
            <Button buttonText="Cancel" type="primary" size="long" @action="toogleDeleteConfirmModal" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment'
import { showVueToast } from '@/utils'
import { mapActions } from 'vuex'
export default {
  name: 'Kelas',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  mounted: function () {
    const getData1 = Promise.resolve(this.getListDepartment())
    const getData2 = Promise.resolve(this.getListStatus())

    Promise.all([getData1, getData2]).then(() => {
      this.initData()
    })
  },
  data: function () {
    return {
      isDeleteConfirmationModalVisible: false,
      options: {
        limit: 11,
        page: 0,
        q: '',
        direction: 'DESC',
        sortBy: 'updatedDate'
      },
      isFetching: false,
      isSearching: false,
      currentKeyword: '',
      employeeSelected: {},
      employeeListResult: [],
      totalClass: 0,
      masterId: '',
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      isKeywordSameTwice: false,
      existingEmployee: '',
      modalEmployee: false,
      fileList: [],
      fileName: '',
      typeFileOrImage: '',
      response: { name: '' },
      tmp: '',
      isReset: false,
      filterSelected: {
        department: null,
        contract: null,
        activeEmployee: null
      },
      dropdownItems: {
        status: [
          {
            id: true,
            name: 'Active'
          },
          {
            id: false,
            name: 'Inactive'
          }
        ],
        department: [],
        contract: []
      }
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    toogleDeleteConfirmModal() {
      this.isDeleteConfirmationModalVisible = !this.isDeleteConfirmationModalVisible
    },
    formatDate(date) {
      if (date) {
        return moment(date).local().format('DD MMM YYYY')
      } else {
        return '-'
      }
    },
    importEmployee() {
      this.modalEmployee = true
    },
    createFile(file) {
      this.fileName = file.name
      this.typeFileOrImage = file.type
      let reader = new FileReader()
      reader.onload = (e) => {
        this.response.name = e.target.result
      }
      reader.readAsDataURL(file)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-yellow-200')
      }
    },
    onChange() {
      this.fileList = [...this.$refs.file.files]
      this.createFile(this.$refs.file.files[0])
    },
    remove(i) {
      this.fileList.splice(i)
    },
    dragFile(e) {
      e.preventDefault()
      let droppedFiles = e.dataTransfer.files
      this.fileList.push(droppedFiles[0])
      this.createFile(this.fileList[0])
    },
    cancelChangeFile() {
      this.modalEmployee = false
      this.fileList = []
    },
    async saveChangeFile() {
      if (!this.fileList.length) {
        alert('File harus di isi')
        return
      }
      this.showLoading()
      if (this.typeFileOrImage === 'text/csv') {
        await this.upladImageControl(this.response.name)
        this.modalEmployee = false
        this.fileList = []
        return
      }
      setTimeout(() => {
        this.hideLoading()
        this.fileList = []
        showVueToast('Document must be in csv format', 'error', 3000)
      }, 3000)
    },
    async upladImageControl(url) {
      let baseUrl
      switch (process.env.NODE_ENV) {
        case 'production':
          baseUrl = 'https://prod-api-v4.g2academy.co'
          break
        case 'staging':
          baseUrl = 'https://staging-api-v4.g2academy.co'
          break
        case 'beta':
          baseUrl = 'https://dev-api-v4.g2academy.co'
          break
      }
      const { data } = await axios({
        method: 'GET',
        url: `${baseUrl}/uploads?contentType=${this.typeFileOrImage}`
      })
      this.apiImg = data
      let binary = atob(url.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: this.typeFileOrImage })
      await fetch(this.apiImg.uploadUrl, {
        method: 'PUT',
        body: blobData
      })
      const payload = {
        temporaryFileUrl: `${this.apiImg.uploadUrl}/${this.apiImg.key}`,
        customerId: this.customerId
      }
      const new_url = {
        production: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/import`,
        staging: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/import`,
        beta: `${process.env.VUE_APP_API_BASE_URL_HR}/employees/import`
      }
      await axios
        .post(
          // `${baseUrl}/new/hr/employees/import`,
          new_url[process.env.NODE_ENV],
          payload
        )
        .then((res) => {
          this.hideLoading()
          showVueToast('Import Document Success', 'success', 2000)
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('Import Document Failed', 'error', 2000)
        })
    },
    initData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          customerId: this.customerId,
          departmentId: this.filterSelected.department ? this.filterSelected.department?.id : null,
          statusId: this.filterSelected.contract ? this.filterSelected.contract?.id : null,
          active: this.filterSelected.activeEmployee ? this.filterSelected.activeEmployee?.id : null
        },
        ...this.options
      })
      this.$store
        .dispatch('employee/GET_LIST_EMPLOYEE', {
          masterType: this.$route.meta.name,
          params: paramsTemp
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
          this.hideLoading()
        })
    },
    unlock() {
      if (this.unlockPassword == this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        showVueToast('Password invalid', 'warning', 2000)
      }
    },
    onPageChange(params) {
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage - 1
      this.initData()
    },
    onSortChange(params) {
      this.options.sortBy = params[0].field
      if (this.options.sortBy == 'btn' || this.options.sortBy == 'role') {
        return
      }

      if (params[0].type == 'none') {
        this.options.direction = 'DESC'
        this.options.sortBy = 'updatedDate'
      } else if (params[0].type == 'desc') {
        this.options.direction = 'DESC'
      } else {
        this.options.direction = 'ASC'
      }

      this.initData()
    },
    onSearch() {
      this.initData()
    },
    editMaster(masterId) {
      this.$router.push('/employee/edit/' + masterId)
    },
    detailMaster(masterId) {
      this.$router.push('/employee/detail/' + masterId)
    },
    showDeleteConfirmModal(employee) {
      this.masterId = employee.id
      this.existingEmployee = employee.name
      this.toogleDeleteConfirmModal()
    },
    deleteMaster() {
      this.showLoading()
      let paramsTemp = queryString.stringify({
        ...{
          customerId: this.customerId
        },
        ...this.options
      })
      this.$store
        .dispatch('employee/DELETE_EMPLOYEE', {
          masterType: this.$route.meta.name,
          masterId: this.masterId
        })
        .then((resp) => {
          if (resp.data.status == '200 OK') {
            this.initData()
            showVueToast('Deleted Employee Success', 'success', 2000)
            this.toogleDeleteConfirmModal()
          }
          this.hideLoading()
        })
        .catch((err) => {
          this.totalClass = err.data.length
          this.hideLoading()
          this.toogleDeleteConfirmModal()
        })
    },
    addData() {
      this.$router.push('/' + this.$route.meta.name + '/Add')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'

      return result
    },
    async clearFilter() {
      this.showLoading()
      this.isReset = !this.isReset
      this.filterSelected = {
        department: null,
        contract: null,
        activeEmployee: null
      }
      this.currentPage = 0
      this.options = {
        limit: 11,
        page: 0,
        q: '',
        direction: 'DESC',
        sortBy: 'updatedDate'
      }
      this.initData()
    },
    getListDepartment() {
      this.$store.dispatch('employee/GET_LIST_DEPARTMENT').then((response) => {
        this.dropdownItems.department = response.data.data
      })
    },
    getListStatus() {
      this.$store.dispatch('employee/GET_LIST_EMPLOYEE_STATUS').then((response) => {
        this.dropdownItems.contract = response.data.data
      })
    }
  },
  computed: {
    keyword() {
      return this.options.q
    },
    customerId() {
      return localStorage.getItem('client')
    },
    data: function () {
      const resp = this.$store.getters['employee/list_employee'] ? this.$store.getters['employee/list_employee'] : []
      return resp.map((e) => ({
        ...e,
        firstJoinDate: e.firstJoinDate ? e.firstJoinDate : '-',
        department: {
          ...e.department,
          name: e.department
            ? e.department.name.replace(/\w+/g, function (w) {
                let hasil = w[0].toUpperCase() + w.slice(1).toLowerCase()
                if (hasil.includes('B2c')) {
                  hasil = hasil.replace('B2c', 'B2C')
                }
                if (hasil.includes('B2b')) {
                  hasil = hasil.replace('B2b', 'B2B')
                }
                if (hasil.includes('Ceo')) {
                  hasil = hasil.replace('Ceo', 'CEO')
                }
                if (hasil.includes('G2lab')) {
                  hasil = hasil.replace('G2lab', 'G2Lab')
                }
                if (hasil.includes('Ir')) {
                  hasil = hasil.replace('Ir', ' IR')
                }
                if (hasil.includes('Hrd')) {
                  hasil = hasil.replace('Hrd', ' HRD')
                }
                if (hasil.includes('And')) {
                  hasil = hasil.replace('And', ' and')
                }
                return hasil
              })
            : '-'
        }
      }))
    },
    totalRecords: function () {
      return this.$store.getters['employee/paginate'].total ? this.$store.getters['employee/paginate'].total : 0
    },
    columns: function () {
      return [
        { field: 'name', label: 'Employee' },
        { field: 'department.name', label: 'Department' },
        { field: 'role', label: 'Role' },
        { field: 'contract.name', label: 'Contract' },
        { field: 'lastContractDate', label: 'End Contract Date', tdClass: 'text-center', thClass: 'text-center' },
        { field: 'status', label: 'Status', tdClass: 'text-center', thClass: 'text-center' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    },
    filters: function () {
      return [{ field: 'q', label: 'Search Employee' }]
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    },
    isClearBtnActive() {
      return this.filterSelected.department || this.filterSelected.contract || this.filterSelected.activeEmployee ? true : false
    }
  },
  created: function () {},
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    },
    keyword(value) {
      if (value === this.currentKeyword) {
        if (!this.isKeywordSameTwice) this.isSearching = true
      } else {
        this.employeeSelected = {}
        this.isKeywordSameTwice = true
        this.isSearching = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.text-10px {
  font-size: 10px;
}

.drop:hover .dropdowns {
  visibility: visible;
  opacity: 1;
  padding-top: 8px;
}

.dropdowns {
  visibility: hidden;
  opacity: 0;
  padding-top: 0;
  transition: all 300ms ease-out;
}

.modal-mask-ChangeFile {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: red; */
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper-ChangeFile {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal ChangeFile */
.modalChangeFile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  padding: 30px 40px;
  height: auto;
  background-color: white;
  border-radius: 8px;
}

/* Style Modal */
.modal-dialog-ChangeFile {
  top: 5px;
  left: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  border-radius: 8px;
}

.modal-content-ChangeFile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  border: none;
  border-radius: 8px;
}

/* End Modal ChangeFile */
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}

.green {
  background: linear-gradient(#f4f5f8, #57c137);
}

.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}

/* Additional Non-Tailwind CSS Class */
.gap-10px {
  gap: 10px;
}

.table-container-border {
  border: 1px solid #dcdfe6;
}
</style>
